<template>
	<div>
		<section class="section is-main-section pb-0 is-align-items-center">
			<div class="box table-filter">
				<div class="is-flex is-align-items-center">
					<div class="table-filter__item">
						<b-field label="Tarih">
							<finance-datepicker
								:lang="datepicker.datePickerLang"
								v-mask="'##.##.####-##.##.####'"
								v-model="form.date"
								type="date"
								format="DD.MM.YYYY" />
						</b-field>
					</div>
					<div class="table-filter__item">
						<b-field label="Site">
							<b-select size="is-small" v-model="form.siteId">
								<option :value="null">-</option>
								<option
									:value="item.uuid"
									v-for="item in sites"
									:key="item.uuid">
									{{ item.name }}
								</option>
							</b-select>
						</b-field>
					</div>
					<div
						class="is-flex is-flex-grow-1 is-align-self-flex-end is-justify-content-end">
						<b-button
							icon-left="sync"
							size="is-small"
							type="is-info"
							@click="getReport"
							>Yenile</b-button
						>
					</div>
				</div>
			</div>
		</section>
		<section class="section is-main-section">
			<finance-table
				ref="table"
				:settings="table.settings"
				:filter="table.filter"
				:page="table.page" />
		</section>
	</div>
</template>

<script>
	import ClientsTableSample from "@/components/ClientsTableSample"
	import CardComponent from "@/components/CardComponent"
	import { DaySummaryService as Service, SiteService } from "@/services"

	export default {
		name: "CageTurnoverList",
		components: {
			CardComponent,
			ClientsTableSample,
		},
		data() {
			return {
				sites: [],
				form: {
					siteId: null,
					date: new Date(),
				},
				table: {
					filter: [],
					settings: {
						service: Service,
						method: "list",
						init: false,
						columns: [
							{
								field: "date",
								label: "Devredilen Gün",
								sortable: true,
								viewMethod: (val) =>
									this.dateFormat(
										val,
										this.dateFormats.normalDate
									),
							},
							{
								field: "siteName",
								label: "Site",
							},
							{
								field: "deposit",
								label: "Yatırım",
								column: "row",
								renderHtml: true,
								viewMethod: (val) =>
									`${this.numberFormat(val.deposit)}`,
							},
							{
								field: "deposit",
								label: "Yatırım Kom.",
								column: "row",
								renderHtml: true,
								viewMethod: (val) =>
									`${this.numberFormat(
										val.depositCommission
									)}`,
							},
							{
								field: "manualDeposit",
								label: "M. Yatırım",
								column: "row",
								renderHtml: true,
								viewMethod: (val) =>
									`${this.numberFormat(val.manualDeposit)}`,
							},
							{
								field: "manualDepositCommission",
								label: "M. Yatırım Kom.",
								column: "row",
								renderHtml: true,
								viewMethod: (val) =>
									`${this.numberFormat(
										val.manualDepositCommission
									)}`,
							},
							{
								field: "withdraw",
								label: "Çekim",
								column: "row",
								renderHtml: true,
								viewMethod: (val) =>
									`${this.numberFormat(val.withdraw)}`,
							},
							{
								field: "withdrawCommission",
								label: "Çekim Kom.",
								column: "row",
								renderHtml: true,
								viewMethod: (val) =>
									`${this.numberFormat(
										val.withdrawCommission
									)}`,
							},
							{
								field: "manualWithdraw",
								label: "M. Çekim",
								column: "row",
								renderHtml: true,
								viewMethod: (val) =>
									`${this.numberFormat(val.manualWithdraw)}`,
							},
							{
								field: "manualWithdrawCommission",
								label: "M. Çekim Kom.",
								column: "row",
								renderHtml: true,
								viewMethod: (val) =>
									`${this.numberFormat(
										val.manualWithdrawCommission
									)}`,
							},
							{
								field: "subTotal",
								label: "Ara Toplam",
								column: "row",
								renderHtml: true,
								viewMethod: (val) =>
									`${this.numberFormat(val.subTotal)}`,
							},
							{
								field: "commissionTotal",
								label: "Toplam Kom.",
								column: "row",
								renderHtml: true,
								viewMethod: (val) =>
									`${this.numberFormat(val.commissionTotal)}`,
							},
							{
								field: "balance",
								label: "Bakiye",
								column: "row",
								renderHtml: true,
								viewMethod: (val) =>
									`${this.numberFormat(val.balance)}`,
							},
							{
								field: "creator.username",
								label: "Oluşturan",
							},
							{
								field: "createdAt",
								label: "Kayıt T.",
								sortable: true,
								viewMethod: (val) =>
									this.dateFormat(
										val,
										this.dateFormats.normalDateTime
									),
							},
						],
					},
				},
			}
		},
		mounted() {
			if (!this.$store.state.user.siteId) this.getSites()
			this.getReport()
		},
		methods: {
			getReport() {

				this.$refs.table.filter.push({
					name: "Site",
					field: "siteId",
					type: "input",
					hidden: true,
					value: this.$store.state.user.siteId || this.form.siteId,
				})
				this.$refs.table.filter.push({
					name: "Tarih",
					field: "date",
					type: "input",
					hidden: true,
					value: this.getTodayDate(this.form.date),
				})
				this.$refs.table.loadAsyncData()
			},
			getSites() {
				SiteService.all({ status: 1 }).then(
					(res) => (this.sites = res.data)
				)
			},
		},
		watch: {
			form: {
				deep: true,
				handler: function () {
					this.getReport()
				},
			},
		},
	}
</script>
